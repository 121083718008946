import { Flex } from '@mantine/core';
import Link from 'next/link';
import * as animationData from '../../lib/lotties/osint_logo_animated.json';
import { loading$ } from '../results/states';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mantine/hooks';

const Lottie = dynamic(() => import('react-lottie'), { ssr: false });

export const BrandLogo = () => {
  const matches = useMediaQuery('(max-width: 480px)');
  return (
    <Link
      href="/"
      style={{ textDecoration: 'none', color: 'white', display: 'flex' }}
      className='navbar14_logo-link w-nav-brand'
      onClick={() => {
        loading$.next({
          init: false,
          total: false,
        });
      }}
    >
      <Flex gap={5} align="center">
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          eventListeners={[]}
          height={matches ? 36 : 48}
          width={matches ? 120 : 150}
        />
      </Flex>
    </Link>
  );
};
