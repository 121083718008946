import { TCountryCode } from '../../lib/constants/country-codes';
import { TOsintModuleResult, TSpecFormatKeys } from '../../lib/module.types';
import { GetObservable, } from '../../lib/observable';
import { TItem } from './timeline/states';

export const dateRangeFilterValues = [
  'All Time',
  'Last Week',
  'Last Month',
  'Last Year',
] as const;

export const mapImage$ = GetObservable<null | string>(null);
export const loading$ = GetObservable<{
  init: boolean;
  total: boolean;
  requestFinished?: boolean;
}>({
  init: false,
  total: false,
  requestFinished: false,
});
export const moduleResults$ = GetObservable<Array<TOsintModuleResult>>([]);
export const totalScanned$ = GetObservable<
  {
    module: string;
    pretty_name: string;
    status: string;
  }[]
>([]);
export const dateRangeFilterValue$ =
  GetObservable<(typeof dateRangeFilterValues)[number]>('All Time');
export const countryCodes$ = GetObservable<TCountryCode[]>([]);
export const moreFilters$ = GetObservable<TSpecFormatKeys[]>([]);
export const siteSearch$ = GetObservable<string>('');
export const timelineItems$ = GetObservable<Array<TItem>>([]);

export const cardsSearchText$ = GetObservable<string>('');