export const clientEnvs = {
  orySdkUrl: process.env.NEXT_PUBLIC_ORY_SDK_URL!,
  mapboxToken: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!,
  cdn: process.env.NEXT_PUBLIC_CDN!,
  appDeployment: process.env.NEXT_PUBLIC_APP_DEPLOYMENT!,
  environmentUrl: process.env.NEXT_PUBLIC_ENVIRONMENT_URL!,
  webflowSite: process.env.NEXT_PUBLIC_WEBFLOW_SITE!,
  gaMeasurementId: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID!,
  osintApiUrl: process.env.NEXT_PUBLIC_OSINT_API_URL!,
};
