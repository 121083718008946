import { Button, ButtonProps } from '@mantine/core';
import React from 'react';
import { text_md_semibold } from './text.css';

export const PrimaryButton = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <Button
      classNames={{
        label: text_md_semibold,
      }}
      styles={{
        label: {
          color: 'white',
        },
      }}
      color="purple.7"
      style={{ borderRadius: 8, height: 48 }}
      {...props}
    />
  );
};

export const SecondaryButton = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <Button
      classNames={{
        label: text_md_semibold,
      }}
      styles={{
        label: {
          color: 'var(--mantine-color-gray-4)',
        },
      }}
      color="gray.10"
      style={{
        border: '1px solid var(--mantine-color-gray-8)',
        borderRadius: 8,
        height: 48,
      }}
      {...props}
    />
  );
};

export const SecondaryButtonWithRef = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <Button
      ref={ref}
      classNames={{
        label: text_md_semibold,
      }}
      styles={{
        label: {
          color: 'var(--mantine-color-gray-4)',
        },
      }}
      style={{
        border: '1px solid var(--mantine-color-gray-8)',
        borderRadius: 8,
        height: 48,
      }}
      color="gray.10"
      {...props}
    />
  );
});

SecondaryButtonWithRef.displayName = 'SecondaryButtonRef';
